import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import type { PartialStore } from '../types'

type DisplayStore = PartialStore<typeof displaySlice>
type DisplayState = {
  fullscreen: boolean
  snackbar: SnackbarState
  bannerOffset: number
}

type OpenSnackbarPayload = {
  message: string
  severity?: SnackbarSeverity
  title?: string
}

type SnackbarSeverity = 'success' | 'info' | 'warning' | 'error'
type SnackbarState = {
  isOpen: boolean
  message: string
  severity?: SnackbarSeverity
  title?: string
}

const initialState: DisplayState = {
  fullscreen: false,
  snackbar: {
    isOpen: false,
    message: ''
  },
  bannerOffset: 0
}

const displaySlice = createSlice({
  name: 'display',
  initialState,
  reducers: {
    closeSnackbar: (state) => {
      // Retain the message, title and severity so the snackbar doesn't change appearance while it fades out
      state.snackbar.isOpen = false
    },
    openSnackbar: (state, action: PayloadAction<OpenSnackbarPayload>) => {
      state.snackbar = {
        isOpen: true,
        message: action.payload.message,
        severity: action.payload.severity ?? 'success',
        title: action.payload.title
      }
    },
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.fullscreen = action.payload
    },
    setBannerOffset: (state, action: PayloadAction<number>) => {
      state.bannerOffset = action.payload
    }
  }
})

const { setFullscreen } = displaySlice.actions
export default displaySlice.reducer

const selectIsFullscreen = (state: DisplayStore) => state.display.fullscreen
const selectSnackbar = (state: DisplayStore) => state.display.snackbar
const selectBannerOffset = (state: DisplayStore) => state.display.bannerOffset

export const useIsFullscreen = () => useSelector(selectIsFullscreen)
export const useSnackbar = () => useSelector(selectSnackbar)
export const useBannerOffset = () => useSelector(selectBannerOffset)
// Provides easy access to dispatching actions
export type OpenSnackbarOptions = { severity?: SnackbarSeverity; title?: string }

export const useSnackbarControls = () => {
  const dispatch = useDispatch()

  const openSnackbar = useCallback(
    (message: string, options: OpenSnackbarOptions = {}) =>
      dispatch(displaySlice.actions.openSnackbar({ message, ...options })),
    [dispatch]
  )

  const closeSnackbar = useCallback(() => dispatch(displaySlice.actions.closeSnackbar()), [dispatch])

  return {
    closeSnackbar,
    openSnackbar
  }
}

export function useLongRunningSnackbar(timeoutSeconds: number = 11) {
  const { openSnackbar } = useSnackbarControls()
  const [shouldShowLongRunningSnackbar, setShouldShowLongRunningSnackbar] = useState(false)
  const [triggerLongRunningSnackbar, setTriggerLongRunningSnackbar] = useState(false)

  useEffect(() => {
    if (shouldShowLongRunningSnackbar) {
      setTimeout(() => {
        setTriggerLongRunningSnackbar(true)
      }, 1000 * timeoutSeconds)
    }
  }, [shouldShowLongRunningSnackbar, timeoutSeconds])

  useEffect(() => {
    if (triggerLongRunningSnackbar && shouldShowLongRunningSnackbar) {
      openSnackbar('Refresh is taking awhile, check back in a few minutes.', {
        severity: 'info'
      })
    }
    if (triggerLongRunningSnackbar) setShouldShowLongRunningSnackbar(false)
    setTriggerLongRunningSnackbar(false)
  }, [triggerLongRunningSnackbar, openSnackbar, shouldShowLongRunningSnackbar])

  return { setShouldShowLongRunningSnackbar }
}

export const useFullscreenControls = () => {
  const dispatch = useDispatch()

  const enterFullscreen = useCallback(() => dispatch(setFullscreen(true)), [dispatch])

  const exitFullscreen = useCallback(() => dispatch(setFullscreen(false)), [dispatch])

  return {
    enterFullscreen,
    exitFullscreen
  }
}

export const useBannerOffsetControls = () => {
  const dispatch = useDispatch()

  return useCallback(
    (offset: number) => {
      dispatch(displaySlice.actions.setBannerOffset(offset))
    },
    [dispatch]
  )
}
